<template>
  <v-container>
    <v-row align="center" class="pa-4">
      <v-col cols="12" class="px-4">
        <v-img src="/images/logo-Polymem.png" max-height="200px" contain />
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col
        v-for="(shortcut, index) in shortcutList"
        :key="`shortcut-${index}`"
        cols="12"
        sm="6"
        md="4"
        xl="3"
        class="pa-2"
      >
        <v-card
          :to="shortcut.link"
          dark
          class="secondary"
          style="color: white"
        >
          <v-card-title primary-title class="text-xs-center" style="padding-bottom: 0px">
            <v-spacer />
            <v-icon color x-large style="font-size: 5rem">
              {{ shortcut.icon }}
            </v-icon>
            <v-spacer />
          </v-card-title>
          <v-card-text style="text-align: center; padding-top: 0">
            <h3 style="font-size: 20px;margin: 10px;">
              {{ shortcut.title }}
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuService from 'src/services/menu'

export default {
  name: 'HomeContainer',
  components: {},
  data () {
    return {}
  },
  computed: {
    ...mapGetters('me', {
      role: 'role',
    }),
    shortcutList () {
      return MenuService.getShortcutList('home', this.role).filter(item => item.roleLevelRequire <= this.role.level)
    },
  },
}
</script>

<style  scoped>
  .item-link-card {
    overflow: hidden !important;
    transition: all 0.1s linear !important;
  }

  .item-link-card:hover {
    transform: translateY(-5px) scale(1.02) !important;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3) !important;
  }
</style>
